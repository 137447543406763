App {
  position: absolute;
  width: 100%;
  height: 100%;
}

.abs-0000 {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.pyggb-IDE {
  display: grid;
  grid-template-rows: min-content 1fr;

  /* Not sure quite what's going on, but there's a flicker of some
  x-overflow when running some programs.  Suppress it. */
  overflow-x: hidden;

  .navbar {
    padding: 0px;
  }

  .container-fluid.MenuBar {
    .navbar-brand:last-child {
      margin-right: 0px;
    }

    background-color: #303030;
    color: white;
    margin: 0rem;
    justify-content: flex-start;

    .ControlButton button {
      padding: 2px 4px;
      display: block; // Avoids little bit of extra space above
    }

    .loading-text {
      margin-left: 3rem;
      color: white;
      font-style: italic;
    }

    .backing-state {
      display: flex;
      flex-direction: row;
      width: 1rem; // Will be adjusted by grow/shrink
      margin-left: 3rem;
      color: white;
      font-style: italic;
      flex-grow: 1;
      flex-shrink: 1;

      .spinner-container {
        width: 2rem;
        height: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .spinner-border {
        visibility: hidden;
        &.visible {
          visibility: visible;
        }
        margin-right: 1rem;
      }

      .FilenameDisplayOrEdit {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 1rem;
      }
    }
  }

  .main-content {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .editor-maybe-errors-outer {
      position: relative;

      .editor-maybe-errors-inner {
        display: grid;

        &.no-errors.content-user-program {
          grid-template-rows: 1fr 0px;
        }
        &.no-errors.content-example {
          grid-template-rows: min-content 1fr 0px;
        }
        &.has-errors.content-user-program {
          grid-template-rows: 2fr 1fr;
        }
        &.has-errors.content-example {
          grid-template-rows: min-content 2fr 1fr;
        }

        &.no-errors .errors-container {
          display: none;
        }

        &.content-user-program .copy-invitation-container,
        &.content-nothing-yet-loaded .copy-invitation-container {
          display: none;
        }

        .copy-invitation-container {
          background-color: #ddd;
          .copy-example {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
              margin: 0.5rem 0rem 0rem;
            }
            button {
              margin: 1rem;
            }
          }
        }

        .editor {
          position: relative;
          .busy-overlay {
            display: none;

            &.booting,
            &.loading {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              background-color: black;
              opacity: 30%;
            }
          }
        }

        .errors-container {
          position: relative;

          .ErrorList {
            overflow: auto;
            border: 2px solid #a00;
            border-radius: 2px;

            .error-list-inner {
              h1 {
                font-size: 1.5rem;
                font-style: bold;
                margin-bottom: 1.5rem;
              }
              margin: 1rem;

              > ul {
                padding-left: 0px;
                > li {
                  list-style: none;
                }

                ul > li {
                  list-style: disc;
                  p {
                    margin: 0.25rem 0px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .results {
      display: grid;
      grid-template-rows: 2fr minmax(9rem, 1fr);

      .stdout-outer {
        background-color: #303030;
        color: #eee;
        position: relative;
      }
      .stdout-inner {
        margin: 0.5rem;
        overflow-y: auto;
      }
    }
  }
}

@mixin hoverable-ul-of-cards {
  padding: 0px;
  li {
    list-style: none;
    user-select: none;
    cursor: pointer;
    background-color: #eee;
    margin: 0.5rem 0rem;
    padding: 0.5rem;
    border-radius: 6px;
    &:hover {
      background-color: #ddd;
    }
  }
}

.FileChoice-list {
  @include hoverable-ul-of-cards();

  li {
    display: grid;
    grid-template-columns: 1fr auto;
    span.file-name {
      display: flex;
      justify-content: left;
      align-items: center;
    }
    button {
      visibility: hidden;
      padding: 2px 6px;
    }
    &:hover button:not([disabled]) {
      visibility: visible;
    }
  }
}

ul.ExampleList {
  @include hoverable-ul-of-cards();
  li {
    h1 {
      font-size: 1.333rem;
    }
  }
}

.FilenameDisplayOrEdit {
  padding: 0.25rem;
  user-select: none;
  &:hover {
    background-color: #606060;
  }
}

.AboutPyGgbModal {
  .modal-body {
    padding-bottom: 0px;
  }

  section.logo-and-text {
    display: grid;
    grid-template-columns: auto 1fr;

    p {
      margin-bottom: 0px;
      &:first-child {
        margin-top: 0.5rem;
        margin-right: 1rem;
      }
    }

    img {
      width: 60px;
    }

    margin-bottom: 1rem;
  }
}

.ShareAsUrlModalBody {
  height: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &.computing {
    justify-content: center;
  }

  &.ready {
    .content {
      width: 100%;

      .text-and-button {
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input {
          font-size: 0.85rem;
        }
        button {
          padding: 0.25rem;
          font-size: 0.9rem;
          width: 3rem;
        }
      }
    }
  }
}
